<template>
    <div id="order-box">
        <h3>订单管理</h3>
        <el-table :data="orderList" :cell-style="{padding:'2px 0'}" height="634" border stripe>
            <el-table-column prop="id" label="id" align="center" width="100"></el-table-column>
            <el-table-column prop="user_id" label="用户id" align="center" width="100"></el-table-column>
            <el-table-column prop="order_no" label="订单号" align="center" width="180"></el-table-column>
            <el-table-column label="价格" width="100" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.price / 100}} 元</span>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名" align="center" width="180"></el-table-column>
            <el-table-column label="付款状态" width="180" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.pay_state == 1" style="color:green;">已付款</span>
                    <el-button v-if="scope.row.pay_state == 2" @click="toPay(scope.row)" type="primary">未付款</el-button>
                </template>
            </el-table-column>

            <el-table-column prop="pay_time" label="付款时间" align="center" width="180"></el-table-column>
            <el-table-column label="使用状态" width="180" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.use_state == 1" style="color:green;">已使用</span>
                    <el-button v-if="scope.row.use_state == 2" @click="toHeXiao(scope.row)" type="primary">未使用</el-button>
                </template>
            </el-table-column>

            <el-table-column prop="use_time" label="使用时间" align="center" width="180"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getForm, postJson, dateFormat, copy } from '../tools/tool'

export default {
    name: "OrderView",
    data() {
        return {
            orderList: [],
        }
    },
    created() {
        let userId = this.$route.query.userId;
        console.log("userId: ", userId);
        if (userId) {
            getForm('/getOrderList', { id: Number(userId) })
                .then(data => {
                    console.log("orderList:", data);
                    this.orderList = data.data
                })
        }
    },
    mounted() { },
    methods: {
        toPay(item) {
            let req = copy(item)
            console.log("item: ", item);
            req.pay_state = 1
            req.pay_time = dateFormat(new Date())
            postJson('/updateOrder', req)
                .then(data => {
                    item.pay_state = 1
                    item.pay_time = req.pay_time
                    console.log("data : ", data)
                }).catch(err => {
                    alert(err)
                })
        },
        toHeXiao(item) {
            console.log("item: ", item);
            let req = copy(item)
            console.log("item: ", item);
            req.use_state = 1
            req.use_time = dateFormat(new Date())
            postJson('/updateOrder', req)
                .then(data => {
                    item.use_state = req.use_state
                    item.use_time = req.use_time
                    console.log("data : ", data)
                }).catch(err => {
                    alert(err)
                })
        },
    },
}
</script>

<style lang="less" scoped>
</style>